<template>
  <div
    :id="anchorId ? anchorId : null"
    ref="triggerRef"
    class="util-section"
    :class="[
      { 'is-entered': isEntered },
      { 'has-disabled-padding': hasDisabledPadding },
      { 'has-disabled-top-padding': hasDisabledTopPadding },
      { 'has-disabled-bottom-padding': hasDisabledBottomPadding },
    ]"
    :style="`--section-background-color: ${computedBackgroundColor};`"
  >
    <ProviderColorSchema :color-schema="colorSchema">
      <UtilRendererStoryblok
        :data="mainContent"
        :disable-padding="true"
        :disable-background="true"
        html-tag="div"
        class="util-section__content"
      />
    </ProviderColorSchema>
  </div>
</template>

<script setup>
const props = defineProps({
    colorSchema: {
        type: String,
        required: true,
    },

    anchorId: {
        type: String,
        default: '',
    },

    backgroundColor: {
        type: String,
        default: '',
    },

    isLightText: {
        type: Boolean,
        default: false,
    },

    mainContent: {
        type: Array,
        default: () => [],
    },

    hasDisabledTopPadding: {
        type: Boolean,
        default: false,
    },

    hasDisabledBottomPadding: {
        type: Boolean,
        default: false,
    },
});

const triggerRef = ref(null);
const isEntered = ref(false);
const computedBackgroundColor = computed(() => props.backgroundColor);
const anchorStore = useAnchorStore();

const hasDisabledPadding = computed(() => {
    const firstElement = props.mainContent[0];
    if (firstElement && firstElement.component === 'BlockSectionIntro') {
        return true;
    }

    return false;
});

onMounted(() => {
    const { createScrollTrigger } = useScrollTrigger();

    if (props.anchorId) {
        anchorStore.setAnchor(props.anchorId);
    }

    createScrollTrigger({
        trigger: triggerRef.value,
        start: 'top center',
        end: 'bottom center',
        onEnter: () => {
            anchorStore.setActiveAnchor(props.anchorId);
        },
        onEnterBack: () => {
            anchorStore.setActiveAnchor(props.anchorId);
        },
    });

    window.setTimeout(() => {
        isEntered.value = true;
    }, 100);
});
</script>

<style lang="scss" scoped>
.util-section {
    @include fluid('padding-top', 60px, 90px);
    @include fluid('padding-bottom', 60px, 90px);

    background-color: var(--section-background-color, transparent);

    .main-content > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    &.has-disabled-padding {
        padding-top: 0;
    }

    &.has-disabled-bottom-padding {
        padding-bottom: 0;
    }

    &.has-disabled-top-padding {
        padding-top: 0;
    }

}
</style>
