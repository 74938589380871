<template>
  <div
    class="atom-credit"
    :class="{ 'has-image': image?.filename }"
  >
    <div
      v-if="image?.filename"
      class="atom-credit__image"
    >
      <AtomImage
        :image="image"
        behaviour="cover"
        :alt="image.alt"
        provider="storyblok"
      />
    </div>
    <div class="atom-credit__content">
      <div
        v-if="showLine"
        class="atom-credit__line"
      />

      <div v-if="title" class="atom-credit__title">
        {{ title }}
      </div>

      <div class="atom-credit__text">
        <AtomRichtext
          v-if="text"
          :html="text"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    /*
        Props for manual calling
    */
    title: {
        type: String,
        default: '',
    },

    text: {
        type: [Object, String],
        default: null,
    },

    image: {
        type: Object,
        default: null,
    },

    showLine: {
        type: Boolean,
        default: false,
    },
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.atom-credit {
    @include grid-layout(8);
    @include fluid('margin-bottom', 60px, 120px);

    @include mobile {
        @include grid-layout(3);
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.atom-credit__line {
    width: 68px;
    height: 2px;
    margin-bottom: 17px;
    margin-bottom: 5px;
    background-color: v-bind(color1);
}

.atom-credit__title {
    color: v-bind(color1);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}

.atom-credit__image {
    position: relative;
    overflow: hidden;
    aspect-ratio: 1;

    @include grid-columns(1, 2, false);

    @include mobile {
        @include grid-columns(1, 1, false);
    }
}

.atom-credit__content {
    @include grid-columns(1, 5, false);

    .has-image & {
        @include grid-columns(3, 3, false);

        @include mobile {
            @include grid-columns(2, 2, false);
        }
    }
}
</style>
