export default (settings, markers = false) => {
    if (!settings.el) {
        throw new Error('You must provide an element to use TransformScroll');
    }

    /* Set up defaults */
    const defaults = {
        el: null,
        delay: 0,
        properties: {
            x: null,
            y: null,
            negate: false,
            skewX: null,
            skewY: null,
            rotate: null,
            scale: null,
        },
        ease: 'easeInOutSine',
        varBase: 'scroll',
        triggerStart: 'top bottom',
        triggerEnd: 'bottom top',
    };

    /* Merge options */
    const options = { ...defaults, ...settings };

    /* Set up variables */
    const easingCollection = useEasing();
    const easing = easingCollection[options.ease];
    const variableBase = `--${options.varBase}`;

    /* Calculate the transformvalue */
    const _calculateTransformValue = (progress, distance) => {
        const easeResult = easing(progress);

        const result = options.negate
            ? (distance * -1) + easeResult * distance
            : distance - easeResult * distance;
        return result;
    };

    /* Build the transform string */
    const _buildTransformString = (properties, progress = 0) => {
        const transformString = [];

        Object.entries(properties).forEach(([key, property]) => {
            const { distance, unit } = property;
            let transformValue = null;
            if (key === 'x') {
                transformValue = 'translateX';
            }

            if (key === 'y') {
                transformValue = 'translateY';
            }

            if (key === 'skewX') {
                transformValue = 'skewX';
            }

            if (key === 'skewY') {
                transformValue = 'skewX';
            }

            if (key === 'rotate') {
                transformValue = 'rotate';
            }

            if (key === 'scale') {
                transformValue = 'scale';
            }

            transformString.push(
                `${transformValue}(${_calculateTransformValue(progress, distance)}${unit})`,
            );
        });

        return transformString.join(' ');
    };

    const addTrigger = () => {
        /* Add the scroll margin if we're using a y distance */
        if (options.properties.y) {
            options.el.style.setProperty(
                `${variableBase}-margin`,
                `${options.properties.y.distance}${options.properties.y.unit}`,
            );
        }

        /* add the initial transform */
        options.el.style.setProperty(
            `${variableBase}-transform`,
            _buildTransformString(options.properties),
        );

        /* add the delay if wanted */
        if (options.delay) {
            options.el.style.transition = `transform ${options.delay}s linear`;
        }

        /* Add scrolltrigger */
        const { createScrollTrigger } = useScrollTrigger();
        const scrollTrigger = createScrollTrigger({
            trigger: options.el,
            start: options.triggerStart,
            end: options.triggerEnd,
            markers,
            onUpdate: (self) => {
                const { progress } = self;
                options.el.style.setProperty(
                    `${variableBase}-transform`,
                    _buildTransformString(options.properties, progress),
                );
            },
        });

        scrollTrigger.refresh();

        return scrollTrigger;
    };

    return {
        addTrigger,
    };
};
