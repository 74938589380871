<template>
  <UtilWrapperLayout>
    <UtilGrid>
      <div
        class="block-facts-section is-extra-wide"
        :class="{
          'is-two-column': itemLength === 2,
          'is-single-column': itemLength === 1,
          'is-map': data?.facts[0]?.component === 'BlockMap',
        }"
      >
        <div
          v-for="fact of data.facts"
          :key="fact._uid"
          v-editable="fact"
          class="block-facts-section__item"
        >
          <BlockCounter
            v-if="fact.component === 'BlockCounter'"
            :data="fact"
          />

          <BlockSpinner
            v-if="fact.component === 'BlockSpinner'"
            :data="fact"
          />

          <BlockMap
            v-if="fact.component === 'BlockMap'"
            :data="fact"
          />

          <BlockGraph
            v-if="fact.component === 'BlockGraph'"
            :data="fact"
          />
        </div>
      </div>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const itemLength = ref(props.data.facts?.length);
</script>

<style lang="scss" scoped>
.block-facts-section {
    @include grid-layout(1, 0);
    @include default-content-columns;
    overflow: hidden;
    width: 100%;
    max-width: 100%;

    padding-top: 48px;
    padding-bottom: 48px;
    border-top: 1px solid var(--c-white);

    &.is-map {
        padding-top: 0;
        border-top: none;
    }

    @include mobile {
        padding-top: 10px;
    }

    &.is-two-column {
        @include grid-layout(2, 0);

        @include mobile {
            @include grid-layout(1, 0);
        }
    }
}

.block-facts-section__item {
    &:first-child {
        .is-two-column & {
            border-right: 1px solid var(--c-white);

            @include mobile {
                padding-bottom: 48px;
                border-right: none;
                border-bottom: 1px solid var(--c-white);
            }
        }
    }
}
</style>
