<template>
  <div class="block-text-image-wrapper">
    <UtilWrapperLayout>
      <UtilGrid>
        <ProviderColorSchema :color-schema="computedColorSchema">
          <div
            class="block-text-image is-multiple"
            :class="{ 'is-reversed': direction === 'reversed' }"
          >
            <div
              ref="leftColumnRef"
              class="block-text-image__column is-text"
            >
              <BlockTextBox
                :text="text"
                :disable-scroll-effect="true"
              />
            </div>
            <div
              ref="rightColumnRef"
              class="block-text-image__column is-image"
            >
              <div class="block-text-image__column-inner">
                <AtomHeading
                  v-if="title"
                  :text="title"
                  font-size="h2"
                  html-tag="h2"
                  class="block-text-image__title"
                />

                <div class="block-text-image__image-wrapper">
                  <AtomImage
                    v-if="image?.filename"
                    :image="image"
                    behaviour="cover"
                    provider="storyblok"
                  />
                </div>
              </div>
            </div>
          </div>
        </ProviderColorSchema>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>
const props = defineProps({
    text: {
        type: String,
        required: true,
    },

    title: {
        type: String,
        required: true,
    },

    color: {
        type: String,
        default: null,
    },

    direction: {
        type: String,
        default: 'normal',
    },

    image: {
        type: Object,
        required: true,
    },
});

const colorSchema = inject('colorSchema');
const computedColorSchema = computed(() => props.color || colorSchema.value);

const distance = 75;
const leftColumnRef = ref(null);
const rightColumnRef = ref(null);

onMounted(() => {
    const clientStore = useClientDeviceStore();
    const properties = {
        x: {
            distance: distance * 1.25,
            unit: 'px',
        },
    };

    if (!clientStore.isMobile) {
        properties.y = {};
        properties.y = {
            distance: distance * 1.25,
            unit: 'px',
        };
    }

    const { addTrigger: addLeftTrigger } = useTransformScroll({
        el: leftColumnRef.value,
        varBase: 'column',
        ease: 'easeOutSine',
        negate: props.direction !== 'reversed',
        triggerStart: 'top bottom',
        triggerEnd: '25% center',
        properties,
    });

    const { addTrigger: addRightTrigger } = useTransformScroll({
        el: rightColumnRef.value,
        varBase: 'column',
        negate: props.direction === 'reversed',
        ease: 'easeOutSine',
        triggerStart: 'top bottom',
        triggerEnd: '25% center',
        properties,
    });

    addLeftTrigger();
    addRightTrigger();
});
</script>

<style lang="scss" scoped>
.block-text-image-wrapper {
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
}

.block-text-image {
    @include default-content-columns;
    @include grid-layout(2, 0);
    @include fluid('margin-bottom', 60px, 120px);

    @include mobile {
        @include grid-layout(1, 0px);
    }
}

.block-text-image__image-wrapper {
    position: relative;
    overflow: hidden;
    aspect-ratio: 29/21;
}

.block-text-image__column {
    order: 1;
    transform: var(--column-transform);

    &.is-text {
        order: 2;

        @include mobile {
            order: 2;
        }
    }

    &.is-image {
        order: 3;

        .is-reversed & {
            order: 1;
        }

        @include mobile  {
            order: 1;
        }
    }
}

.block-text-image__title {
    @include fluid('padding-top', 20px, 50px);
    @include fluid('padding-bottom', 20px, 50px);
    @include fluid('padding-left', 0px, 30px);
    @include fluid('padding-right', 0px, 30px);

    font-family: var(--f-family--regular);

    @include mobile {
        padding: var(--page-padding);
    }

    .is-reversed & {
        @include mobile {
            margin-left: 0;
        }
    }
}
</style>
