<template>
  <div
    ref="triggerRef"
    class="block-section-intro"
    :class="{ 'is-light-theme': isLightTheme }"
    :style="`--intro-background-color: ${computedBackgroundColor};`"
  >
    <ProviderColorSchema :color-schema="backgroundColor || colorSchema">
      <BlockSectionIntroItem
        :data="{
          type: 'pin',
          pretitle: 'Fokus',
          layout: 'left',
          color: 'is-year',
          title: title,
          pretitle: pretitle,
          isSmallTitle: is_small_title,
        }"
        :pin-distance="mainPinDistance"
        :is-main-pin="true"
        :is-main-intro="is_main_intro"
      />

      <BlockSectionIntroItem
        v-for="(item, index) in sections"
        :key="item._uid"
        :pin-distance="parseFloat(item.pin_distance)"
        :is-last-item="index === sections.length - 1"
        :data="item"
        :is-main-intro="is_main_intro"
      />
    </ProviderColorSchema>
  </div>
</template>

<script setup>

const props = defineProps({
    is_main_intro: {
        type: Boolean,
        default: false,
    },

    is_small_title: {
        type: Boolean,
        default: false,
    },

    title: {
        type: Object,
        default: null,
    },

    pretitle: {
        type: String,
        default: '',
    },

    sections: {
        type: Array,
        default: () => [],
    },

    backgroundColor: {
        type: String,
        default: null,
    },

    backgroundColorBrightness: {
        type: [Number, String],
        default: 6,
    },

    textColor: {
        type: String,
        default: 'bright',
    },
});

const triggerRef = ref(null);
const mainPinDistance = 0.5 + props.sections.reduce(
    (acc, val) => acc + parseFloat(
        val.pin_distance,
    ),
    0,
);

const computedBackgroundColor = computed(() => {
    if (props.backgroundColor) {
        return `var(--c-${props.backgroundColor}-${props.backgroundColorBrightness || 6})`;
    }

    return 'transparent';
});

const colorSchema = inject('colorSchema');

const computedTextColor = computed(() => {
    if (props.textColor === 'dark') {
        return `var(--c-${colorSchema.value}-1)`;
    }

    return 'var(--c-white)';
});

const isLightTheme = computed(() => props.isLightTheme);
provide('isLightTheme', isLightTheme);

</script>

<style lang="scss">
.block-section-intro {
    @include fluid('--margin-bottom', 60px, 120px);
    display: block;

    margin-bottom: var(--margin-bottom);
    background-color: var(--intro-background-color);
    --parent-text-color: v-bind('computedTextColor');

    .pin-spacer {
        z-index: 10 !important;
    }

    & + .block-facts {
        @include fluid('--margin-bottom', 60px, 120px);
        margin-top: calc(var(--margin-bottom) * -1);
    }
}
</style>
