<template>
  <div
    ref="blockTextBoxRef"
    class="block-text-box"
    :class="[
      { 'use-slow-scroll': useSlowScroll },
    ]"
  >
    <div
      v-if="text"
      class="block-text-box__inner"
    >
      <AtomHeading
        v-if="title"
        class="block-text-box__title"
        :text="title"
        font-size="h3"
        html-tag="h2"
      />

      <AtomRichtext
        class="block-text-box__text"
        :html="text"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    disableScrollEffect: {
        type: Boolean,
        default: false,
    },
});

const rawDistance = 20;
const useSlowScroll = ref(!props.disableScrollEffect);
const blockTextBoxRef = ref(null);

const { addTrigger } = useSlowdownScroll(rawDistance, 'vh', false);

onMounted(() => {
    if (useSlowScroll.value) {
        window.setTimeout(() => {
            addTrigger(blockTextBoxRef);
        }, 50);
    }
});

const colorSchema = inject('colorSchema');

const computedBackgroundColor = computed(() => (
    useSlowScroll.value
        ? `var(--c-${colorSchema.value}-11)`
        : `var(--c-${colorSchema.value}-13)`
));
</script>

<style lang="scss" scoped>
.block-text-box {
    @include fluid('--bottom-spacing', 60px, 120px);

    margin-bottom: var(--bottom-spacing);
    background-color: v-bind(computedBackgroundColor);

    &.use-slow-scroll {
        margin-top: var(--scroll-margin);
        margin-bottom: calc(var(--bottom-spacing) + var(--scroll-margin));
        transform: var(--scroll-transform);
    }

    &:not(.use-slow-scroll) {
        @include mobile {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.block-text-box__inner {
    @include fluid('padding', 20px, 80px);
    width: 100%;
}

.block-text-box__title {
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
}
</style>
