<template>
  <UtilWrapperLayout
    class="atom-button-back__wrapper"
  >
    <UtilGrid>
      <button
        type="button"
        class="atom-button-back"
        @click="navigateBack"
      >
        <IonIcon
          icon-name="chevron-left"
          class="atom-button-back__icon"
        />

        <p>
          {{ label || getStoryblokTranslation('general', 'back_to_overview') }}
        </p>
      </button>
    </UtilGrid>
  </UtilWrapperLayout>
</template>

<script setup>
defineProps({
    label: {
        type: String,
        default: null,
    },
});

const colorSchema = inject('colorSchema');
const color4 = computed(() => `var(--c-${colorSchema.value}-4)`);

const route = useRoute();
const { path } = route;

const router = useRouter();

const navigateBack = () => {
    const { history } = window;
    let pathParts = path.split('/');
    const previousPage = history.state.back;
    pathParts = pathParts.filter((part) => part !== '');
    pathParts.pop();
    let joinedPath = isArrayNotEmpty(pathParts) ? pathParts.join('/') : null;

    if (!joinedPath.startsWith('/')) {
        joinedPath = `/${joinedPath}`;
    }

    if (previousPage === joinedPath) {
        router.back();
        return;
    }

    navigateTo(joinedPath);
};
</script>

<style lang="scss" scoped>
.atom-button-back__icon {
    margin-top: -1px;
    transition: all 0.3s ease;

    ::v-deep(path) {
        stroke: v-bind('color4');
    }
}

.atom-button-back__wrapper {
    margin-bottom: 40px;

    &:hover {
        .atom-button-back__icon {
            transform: translate(-5px, 0)
        }
    }
}

.atom-button-back {
    @include fluid('font-size', 14px, 20px);
    @include default-content-columns;

    display: flex;
    align-items: center;
    color: v-bind('color4');
    cursor: pointer;
    font-family: var(--f-family--regular);
    gap: 10px;
}

</style>
