<template>
  <div class="storyblok-form-registration">
    <UtilWrapperLayout>
      <UtilGrid>
        <div class="storyblok-form-registration__form is-multiple">
          <AtomHeading
            v-if="title"
            :text="title"
            class="storyblok-form-registration__form-title"
            html-tag="h3"
            font-size="h3"
          />

          <AtomRichtext
            v-if="text"
            :html="text"
            class="storyblok-form-registration__form-text"
          />

          <BlockFormController
            :fieldsets="fieldsets"
            :button-text="buttonText"
            @on-submit="onSubmit"
          />
        </div>
      </UtilGrid>
    </UtilWrapperLayout>

    <Transition name="fade-in">
      <AtomPopoverNotification
        v-if="popupVisible"
        position="fixed"
        :text="popupMessage"
        :type="popupType"
        @on-hide="popupVisible = false"
      />
    </Transition>
  </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },

    text: {
        type: String,
        default: '',
    },

    fieldsets: {
        type: Array,
        default: () => [],
    },

    buttonText: {
        type: String,
        default: '',
    },

    errorMessage: {
        type: String,
        default: '',
    },

    successMessage: {
        type: String,
        default: '',
    },

    recipient: {
        type: String,
        default: '',
    },
});

const popupVisible = shallowRef(false);
const popupType = shallowRef('error');
const popupMessage = shallowRef('');

const onSubmit = async (data) => {
    try {
        await useFetch('/api/register', {
            method: 'POST',
            body: {
                data,
                recipient: props.recipient,
            },
        });

        popupType.value = 'success';
        popupMessage.value = props.successMessage;
    } catch (e) {
        popupType.value = 'error';
        popupMessage.value = props.errorMessage;

        useSentryError(e);
    } finally {
        popupVisible.value = true;
    }
};
</script>

<style lang="scss" scoped>
.storyblok-form-registration {
    @include fluid('margin-bottom', 30px, 50px);
}

.storyblok-form-registration__form {
    @include default-content-columns;
    padding: 40px;
    background: var(--c-white);
}

.storyblok-form-registration__form-title {
    margin-bottom: 20px;
}

.storyblok-form-registration__form-text {
    margin-bottom: 20px;
}
</style>
