export default (distance = 100, unit = 'px') => {
    const { easeInOutSine } = useEasing();

    const _calculateTransformValue = (progress) => {
        const easeResult = easeInOutSine(progress);
        const result = easeResult * (2 * distance) - distance;
        return result;
    };

    const addTrigger = (el, negative = false, transformVar = '--scroll-transform') => {
        el.value?.style?.setProperty('--scroll-margin', `${distance}${unit}`);
        el.value?.style?.setProperty(transformVar, `translateY(-${distance}${unit})`);
        const { createScrollTrigger } = useScrollTrigger();

        const scrollTrigger = createScrollTrigger({
            trigger: el.value,
            start: 'top bottom',
            end: 'bottom top',
            onUpdate: (self) => {
                const { progress } = self;
                if (negative) {
                    el.value?.style?.setProperty(transformVar, `translateY(${_calculateTransformValue(progress) * -1}${unit})`);
                } else {
                    el.value?.style?.setProperty(transformVar, `translateY(${_calculateTransformValue(progress)}${unit})`);
                }
            },
        });

        scrollTrigger.refresh();

        return scrollTrigger;
    };

    return {
        addTrigger,
    };
};
