<template>
  <div
    class="block-facts is-extra-wide"
    :class="[
      `is-text-color-${textColor}`,
    ]"
  >
    <BlockFactsSection
      v-for="section of sections"
      :key="section._uid"
      :data="section"
    />
  </div>
</template>

<script setup>
const props = defineProps({
    sections: {
        type: Object,
        default: () => {},
    },

    textColor: {
        type: String,
        default: 'white',
    },

    backgroundColor: {
        type: String,
        default: null,
    },

    backgroundColorBrightness: {
        type: String,
        default: null,
    },
});

const computedBackgroundColor = computed(() => {
    if (props.backgroundColor) {
        return `var(--c-${props.backgroundColor}-${props.backgroundColorBrightness || 6})`;
    }

    return 'transparent';
});

provide('computedBackgroundColor', computedBackgroundColor);

const colorSchema = inject('colorSchema');

const computedTextColor = computed(() => {
    if (props.textColor === 'dark') {
        return `var(--c-${colorSchema.value}-1)`;
    }

    return 'var(--c-white)';
});

const color1 = computed(() => `var(--c-${colorSchema.value}-1)`);
const color4 = computed(() => `var(--c-${colorSchema.value}-4)`);
</script>

<style lang="scss" scoped>
.block-facts {
    @include default-content-columns;
    overflow: hidden;
    width: 100%;
    max-width: 100vw;
    background-color: v-bind('computedBackgroundColor');

    ::v-deep(.atom-richtext) {
        color: v-bind('computedTextColor');
    }

    --parent-text-color: v-bind('computedTextColor');
    --parent-background-color: v-bind('computedBackgroundColor');
    --parent-color-1: v-bind('color1');
    --parent-color-4: v-bind('color4');
}
</style>
