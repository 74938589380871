<template>
  <div class="atom-graph-item">
    <div class="atom-graph-item__number">
      {{ graphNumber }}
    </div>

    <div class="atom-graph-item__graph" />

    <div class="atom-graph-item__label" :class="uniqueId">
      {{ data.label }}
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    progress: {
        type: Number,
        default: 0,
    },

    uniqueId: {
        type: String,
        default: '',
    },
});

const graphHeight = ref(`${props.data.height}px`);
const graphHeightMobile = ref(`${props.data.height_mobile}px`);
const graphNumber = ref(props.data.number);
const graphColor = ref(`#${props.data.color}`);
const progress = computed(() => props.progress);

watch(progress, () => {
    if (props.data.has_decimal) {
        graphNumber.value = Math.round(props.data.number * progress.value * 100) / 100;
        return;
    }
    graphNumber.value = Math.round(props.data.number * progress.value);
});
</script>

<style lang="scss">
.atom-graph-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: var(--f-family--bold);

    &:last-child {
        margin-left: 30px;
    }
}

.atom-graph-item__number {
    position: absolute;
    top: 0;
    left: 50%;
    width: 70px;
    margin-bottom: 8px;
    text-align: center;
    transform: translate(-50%, -50%) translateY(-12px);
}

.atom-graph-item__graph {
    @include fluid('width', 32px, 57px);

    height: calc(v-bind(graphHeight) * v-bind(progress));
    background-color: v-bind(graphColor);

    @include mobile {
        height: calc(v-bind(graphHeightMobile) * v-bind(progress));
    }
}

.atom-graph-item__label {
    position: absolute;
    right: 50%;
    bottom: -30px;
    transform: rotate(-50deg);
    transform-origin: center right;

    @include mobile {
        transform: rotate(-90deg);
    }
}
</style>
