<template>
  <div
    ref="triggerRef"
    class="block-section-intro-item"
    :class="[
      `is-${data.layout}`,
    ]"
  >
    <div
      class="block-section-intro-item__inner is-max"
      :class="{ 'is-hidden': pinIsHidden }"
    >
      <UtilWrapperLayout>
        <UtilGrid>
          <div
            v-if="isMainPin"
            ref="mainPinRef"
            class="block-section-intro-item__element is-left is-intro-main-pin"
          >
            <div class="block-section-intro-item__inner-wrapper">
              <div
                class="block-section-intro-item__pretitle stagger-ref"
              >
                <IonIcon
                  v-if="isMainIntro"
                  class="block-section-intro-item__logo"
                  icon-name="logo-colored"
                />
                <span v-else>{{ data.pretitle }}</span>
              </div>

              <div class="block-section-intro-item__title">
                <AtomRichtext
                  :class="{ 'is-small-title': data.isSmallTitle }"
                  :html="data.title"
                />
              </div>
            </div>
          </div>

          <div
            v-for="item of data.content"
            :key="item._uid"
            ref="staggerRefs"
            class="block-section-intro-item__element stagger-ref"
            :class="[`is-intro-${item.component.toLowerCase()}`]"
          >
            <div
              v-if="item.component === 'AtomSectionIntroRichtext'"
              class="block-section-intro-item__richtext"
            >
              <div
                v-if="item.pretitle"
                class="block-section-intro-item__richtext-pretitle"
              >
                {{ item.pretitle }}
              </div>

              <div
                v-if="item.title"
                class="block-section-intro-item__richtext-title"
              >
                {{ item.title }}
              </div>

              <AtomRichtext
                :html="item.text"
              />

              <template v-if="item.buttons">
                <AtomDownload
                  v-for="button of item.buttons"
                  :key="button._uid"
                  :filename="button.filename"
                  :label="button.label"
                />
              </template>
            </div>

            <div
              v-if="item.component === 'AtomImage'"
              class="block-section-intro-item__image-bled"
            >
              <AtomImage
                :image="item.image"
                :lazy="false"
                :preload="true"
                provider="storyblok"
              />
            </div>
          </div>
        </UtilGrid>
      </UtilWrapperLayout>
    </div>
  </div>
</template>

<script setup>
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    isMainPin: {
        type: Boolean,
        default: false,
    },
    pinDistance: {
        type: [Number],
        default: 1,
    },
    isLastItem: {
        type: Boolean,
        default: false,
    },
    isMainIntro: {
        type: Boolean,
        default: false,
    },
});

const triggerRef = ref(null);
const { height } = useWindowSize({
    includeScrollbar: false,
});

const isPinned = props.data.type === 'pin';
const clientStore = useClientDeviceStore();
const pinIsHidden = ref(false);
const mainPinRef = ref(null);

onMounted(() => {
    /* Disable pinning on mobile if it's other than main pin (text pin) */
    if (!isPinned || (clientStore.isMobile && !props.isMainPin)) return;

    const { createScrollTrigger } = useScrollTrigger();

    let end = height.value * props.pinDistance;
    if (clientStore.isMobile && props.isMainPin) {
        end -= height.value;
    }

    createScrollTrigger({
        trigger: triggerRef.value,
        pin: true,
        start: 'top top',
        end: `+=${end}`,
        scrub: true,
        anticipatePin: 1,
        pinSpacing: !props.isMainPin,

        onLeave: () => {
            if (props.isMainPin && props.isMainIntro) {
                pinIsHidden.value = true;
            }
        },

        onEnterBack: () => {
            if (props.isMainPin && props.isMainIntro) {
                pinIsHidden.value = false;
            }
        },

        onUpdate: (_self) => {
            if (props.isLastItem && props.isMainIntro) {
                if (!triggerRef.value) return;

                if (mainPinRef.value) {
                    mainPinRef.value.style.transition = 'none';
                    mainPinRef.value.style.opacity = 1 - _self.progress;

                    /* In case we wan't it a bit more fancy */
                    // introMainPin.style.transform = `translateX(-${200 * _self.progress}px)`;
                }
            }
        },
    });
});

const colorSchema = inject('colorSchema');
const textColor = computed(() => `var(--c-${colorSchema.value}-1)`);
</script>

<style lang="scss">
.block-section-intro-item  {
    position: relative;
    z-index: 10;

    &.is-right {
        z-index: 1;
    }
}

.block-section-intro-item__richtext-pretitle {
    @include fluid('font-size', 15px, 20px);
    @include fluid('line-height', 23px, 31px);

    margin-bottom: 5px;
    font-family: var(--f-family--bold);
}

.block-section-intro-item__richtext-title {
    @include fluid('font-size', 24px, 46px);
    @include fluid('line-height', 32px, 56px);
    margin-bottom: 20px;
    font-family: var(--f-family--bold);
}

.block-section-intro-item__logo {
    @include fluid('margin-bottom', 0px, 10px);
}

.block-section-intro-item__inner-wrapper {
    grid-column-end: calc(var(--grid-columns) - 2);
    grid-column-start: 4;

    @include tablet {
        grid-column-end: span var(--grid-columns);
        grid-column-start: 1;
    }
}

.block-section-intro-item__image-bled {
    width: calc(100% + var(--grid-offset));
    @include fluid('padding-top', 0px, 140px);

    @include mobile {
        width: calc(100% + var(--page-padding) * 2);
        // margin-top: -50px;
        margin-left: calc(var(--page-padding) * -1);
    }
}

.block-section-intro-item__element {
    height: 100vh;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    .is-hidden & {
        opacity: 0;

        @include mobile {
            opacity: 1;
        }
    }

    &.is-intro-atomimage {
        grid-column-end: span calc(var(--grid-columns) - 5);
        grid-column-start: 6;
        transform: translateY(20vh);
        transition: transform 1.4s $EASE_OUT--EXPO;

        .is-entered & {
            transform: translateY(0);
        }

        img {
            width: 100%;
        }

        @include mobile {
            grid-column-end: span var(--grid-columns);
            grid-column-start: 1;
        }

    }

    &.is-intro-atomsectionintrorichtext {
        @include fluid('padding-top', 120px, 191px);

        grid-column-end: span 9;
        grid-column-start: 10;

        @include tablet {
            grid-column-end: span 7;
            grid-column-start: 6;
        }

        @include mobile {
            height: auto;
            padding-bottom: 100px;
            grid-column-end: span var(--grid-columns);
            grid-column-start: 1;
        }
    }

    &.is-intro-main-pin {
        @include fluid('padding-top', 120px, 191px);

        z-index: 10;
        height: 50vh;
        grid-column-end: span calc(var(--grid-columns) - 2);
        grid-column-start: 3;
        opacity: 0;
        transform: translateX(-200px);
        transition: opacity 0.8s ease-in-out, transform 1.4s $EASE_OUT--EXPO;

        @include tablet {
            grid-column-end: span var(--grid-columns);
            grid-column-start: 1;
        }

        .is-entered & {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

.block-section-intro-item__pretitle {
    @include fluid('font-size', 20px, 28px);

    color: v-bind(textColor);
    font-family: var(--f-family--black);
    line-height: 1.2;

    @include tablet {
        margin-bottom: 20px;
    }

    @include mobile {
        font-size: 40px;
    }
}
</style>
