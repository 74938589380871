<template>
  <div class="block-calculation-results-wrapper">
    <div class="block-calculation-results">
      <div class="block-calculation-results__text">
        {{ data.text }}
      </div>
      <div class="block-calculation-results__result">
        <div v-if="calculatedData">
          {{ convertMonetary(calculatedData[data.key]) }} CHF
        </div>
        <div v-else>
          -
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    colorScheme: {
        type: String,
        default: '',
    },
});

const {
    data,
} = toRefs(props);

const calculatorStore = useCalculatorStore();
const calculatedData = computed(() => calculatorStore.getCalculatedData);
</script>

<style lang="scss" scoped>
.block-calculation-results-wrapper {
    position: absolute;
    right: 0;
    bottom: 46px;
    left: 0;
    display: flex;
    flex-direction: column;
    margin: 0 100px;
    row-gap: 10px;

    @include mobile {
        position: relative;
        bottom: 0;
        width: 100%;
        margin: 0;
    }
}

.block-calculation-results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    background: v-bind(colorScheme);
    color: var(--c-white);

}

.block-calculation-results__text {
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
}

.block-calculation-results__result {
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);

}
</style>
