export default () => {
    const createScrollTrigger = (options = {}) => {
        if (!options.trigger) return false;

        const defaults = {
        };

        const clientStore = useClientDeviceStore();

        if (clientStore.isMobile) {
            const { lenis } = useLenis();
            lenis.value.on('scroll', scrollTrigger.update);

            GSAP.ticker.add((time) => {
                if (lenis.value) {
                    lenis.value?.raf(time * 1000);
                }
            });

            GSAP.ticker.lagSmoothing(0);

            scrollTrigger.config({
                ignoreMobileResize: true,
            });
        }

        const mergedOptions = { ...defaults, ...options };

        return scrollTrigger.create(mergedOptions);
    };

    return {
        ScrollTrigger: scrollTrigger,
        createScrollTrigger,
    };
};
