<template>
  <div class="block-graph-wrapper">
    <div
      ref="counterRef"
      class="block-graph"
    >
      <div class="block-graph__graph">
        <AtomGraphItem
          v-for="item in data.items"
          :key="item._uid"
          :data="item"
          :progress="progressItem"
          :unique-id="uniqueId"
        />
      </div>

      <div v-if="data.text" class="block-graph__text">
        <AtomRichtext
          :html="data.text"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const opacity = ref(0);
const progressItem = ref(0);
const calculateValues = (progress) => {
    opacity.value = progress;
    progressItem.value = progress;
};
const counterRef = ref(null);
const uniqueId = ref(null);

onMounted(() => {
    uniqueId.value = `block-graph-${Math.random().toString(36).substring(7)}`;

    const { createScrollTrigger } = useScrollTrigger();

    createScrollTrigger({
        trigger: counterRef.value,
        start: 'top 80%',
        end: 'top 40%',

        onUpdate: (_self) => {
            calculateValues(_self.progress);
        },
    });
});
</script>

<style lang="scss" scoped>
.block-graph {
    @include fluid('padding-right', 0px, 60px);
    @include fluid('padding-left', 0px, 60px);

    display: flex;
    align-items: center;
    color: var(--parent-text-color, var(--c-white));

    @include tablet-portrait {
        flex-wrap: wrap;
    }

    .is-two-column & {
        justify-content: center;
    }

}

.block-graph__graph {
    display: flex;
    min-width: 50%;
    height: 330px;
    flex-basis: 50%;
    align-items: flex-end;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 180px;
    column-gap: 10px;
    opacity: v-bind(opacity);

    @include tablet-portrait {
        max-width: 100vw;
        flex-basis: 100%;
        margin-top: 0px;
        margin-right: 0;
    }
}

.block-graph__text {
    flex-basis: 50%;

    @include fluid('padding-right', 0px, 60px);
    @include fluid('padding-left', 0px, 60px);

    @include tablet-portrait {
        flex-basis: 100%;
    }

    .is-two-column & {
        align-self: center;
        text-align: center;
    }
}
</style>
