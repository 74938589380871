<template>
  <div v-if="data" class="_nuxt-page">
    <BlockOverlayPopup v-if="data?.popup" :data="data?.popup" />
    <LayoutNewsHero
      v-if="data?.layoutToApply === 'news'"
      :extra-content="data.extraData"
      :first-published-at="data.firstPublishedAt"
      :main-text="data.mainText"
    />
    <LayoutArticleHero
      v-if="data?.layoutToApply === 'activity'"
      :extra-content="data.extraData"
      :first-published-at="data.firstPublishedAt"
    />

    <ProviderColorSchema
      :color-schema="data?.layoutToApply === 'news'
        ? data.colorSchema
        : data.colorSchemaOverride || injectedColorSchema"
    >
      <UtilRendererStoryblok
        v-if="data?.mainContent"
        class="page-main-content"
        :class="{
          'has-no-hero': data.hasNoHero,
          isRtl: isTextRtl,
        }"
        :data="data.mainContent"
        :disable-padding="data?.hasDisabledPadding"
        :rtl="isTextRtl"
      />
    </ProviderColorSchema>

    <LayoutNewsSimilararticles
      v-if="data?.layoutToApply === 'news'"
      :extra-content="data.extraData"
      :sb-story-id="data.storyId"
    />
  </div>
</template>

<script setup>
const flyoutStore = useFlyoutStore();
flyoutStore.setFlyoutState(false);
const filterStore = useFilterStore();

const route = useRoute();
const version = inject('storyblokVersion', 'draft');
const { locale, defaultLocale } = useI18n();
const storyblokApi = useStoryblokApi();
const storyblokContentStore = useStoryblokContentStore();
const injectedColorSchema = inject('colorSchema');

const language = locale.value === defaultLocale.value ? '' : locale.value;
const { path: routePath } = route;

const replacedPath = `/${language}` === routePath ? routePath.replace(`/${language}`, '') : routePath.replace(`/${language}/`, '');

let path = replacedPath === '/' || replacedPath === '' ? '/home' : replacedPath;

if (!path.startsWith('/')) {
    path = `/${path}`;
}

const anchorStore = useAnchorStore();
anchorStore.resetAnchors();

const relationsToResolve = [
    'News.department',
    'News.tags',
    'News.author',
    'News.type',
    'Activity.department',
    'Activity.tags',
    'Activity.country',
    'Activity.type',
    'SliderParticipationItem.tags',
    'StoryblokDownloadHubMini.files',
    'Download File.department',
    'StoryblokFormContact.people',
    'BlockItemCalculationPersonBlock.person',
    'StoryblokEmployeesBlock.people',
    'Person.departments',
    'TeaserItem.article',
    'SliderHeroItem.article',
    'TeaserItem.article',
    'BlockItemContactCard.person',
    'FilterItem.optionsManual',
];

const { data, error } = await useAsyncData(
    `getMainContent/${path}/${locale.value}`,
    () => storyblokApi.get(
        `cdn/stories${path}`,
        {
            version,
            language,
            resolve_links: 'link',
            resolve_relations: relationsToResolve.join(','),
        },
    ),
    {
        transform: (res) => {
            /* Auth for Klik-Guidelines */
            if (res.data.story.content.isAuth) {
                const authStore = useAuthStore();
                const isAuthenticated = authStore.getIsAuthenticated;

                if (!isAuthenticated) {
                    // eslint-disable-next-line consistent-return
                    return navigateTo('/');
                }
            }

            const storyContent = res.data?.story?.content;
            if (!storyContent) {
                return null;
            }
            const layoutToApply = storyContent.component.replace('Page', '').toLowerCase();

            /*
                Storyblok Preview
            */
            const storyId = getNestedData(['data', 'story', 'id'], res);

            /*
                SEO
            */
            const seoData = getNestedData(['data', 'story', 'content', 'seo'], res);

            /*
                If the layout is news, use the news data for the SEO if no SEO data is set
            */
            let mappedSEOData = seoData;

            if (layoutToApply === 'news') {
                mappedSEOData = {
                    ...seoData,
                    title: seoData?.title || storyContent.title || '',
                    description: seoData?.description || storyContent.lead,
                    og_image: seoData?.og_image || storyContent.image_video?.filename,
                };
            }

            /*
                Translated Slugs
            */
            const translatedSlugs = getNestedData(['data', 'story', 'translated_slugs'], res);

            if (isArrayNotEmpty(translatedSlugs)) {
                const slugsObj = {
                    [defaultLocale]: {
                        slug: getNestedData(['data', 'story', 'default_full_slug'], res),
                    },
                };

                translatedSlugs.forEach(({ lang, path: storyblokPath }) => {
                    slugsObj[lang] = { slug: storyblokPath };
                });

                storyblokContentStore.setTranslatedSlugs(slugsObj);
            }

            /*
                Content
            */
            const isTextRtl = getNestedData(['data', 'story', 'content', 'rtl'], res);
            const firstPublishedAt = getNestedData(['data', 'story', 'first_published_at'], res);

            /*
                Set default slug
            */
            const defaultSlug = getNestedData(['data', 'story', 'default_full_slug'], res);

            /*
                Active Languages Pagelevel
            */
            const pageLanguages = getNestedData(['data', 'story', 'content', 'languagesPage'], res);

            let branchLanguages = isArrayNotEmpty(pageLanguages) ? pageLanguages : [
                'de',
                'it',
                'fr',
            ];

            const branches = getStoryblokSetting('language', 'branches');

            if (isArrayNotEmpty(pageLanguages)) {
                branchLanguages = pageLanguages;
            } else {
                branches.value.forEach((branch) => {
                    if (path.includes(branch.path)) {
                        branchLanguages = branch.languages;
                    }
                });
            }

            const languageExists = branchLanguages.find((lang) => lang === language);

            if (!languageExists) {
                const forceLanguage = branchLanguages[0];

                if (forceLanguage !== language) {
                    let currentBasePath = defaultSlug;

                    if (forceLanguage !== defaultLocale) {
                        const foundSlug = translatedSlugs.find(
                            (slug) => slug.lang === forceLanguage,
                        );

                        if (foundSlug) {
                            currentBasePath = foundSlug.path;
                        }
                    }

                    const finalRedirect = forceLanguage === defaultLocale
                        ? `/${currentBasePath}`
                        : `/${forceLanguage}/${currentBasePath}`;

                    return navigateTo(finalRedirect, {
                        replace: true,
                    });
                }
            }

            /*
                Extra data
            */
            const extraData = getNestedData(['data', 'story', 'content'], res);
            const mainContent = getNestedData(['data', 'story', 'content', 'main_content'], res);

            const heroes = [
                'StoryblokHero',
                'StoryblokHeroLinks',
                'StoryblokSliderHero',
                'UtilSection',
                'StoryblokCompass',
                'AtomAnchorInvisible',
            ];
            const hasNoHero = isArrayNotEmpty(mainContent)
                && !heroes.includes(mainContent[0].component);

            /*
                Set route to get back to overview
            */
            if (path.includes('/news') && layoutToApply !== 'news') {
                storyblokContentStore.setOverviewPath(path);
            }

            /*
                Set news text to read if layout is news
            */
            let mainText = '';

            if (layoutToApply === 'news') {
                mainText = storyContent.main_content.reduce((acc, curr) => {
                    let newAcc = acc;
                    if (curr.component === 'StoryblokTextRichtext' || curr.component === 'StoryblokTextHeading') {
                        if (curr.component === 'StoryblokTextRichtext') {
                            const parsedHtml = storyblokApi.richTextResolver.render(curr.text);
                            newAcc += useHtmlToText(parsedHtml);
                        }
                        return newAcc + (typeof curr.text === 'string' ? curr.text : '');
                    }
                    return newAcc;
                }, '');
            }

            /*
                Color Schema
            */
            const colorSchema = extraData.department?.content.color_schema;

            const activityStore = useActivityStore();
            activityStore.setCurrentLayout(layoutToApply);

            storyblokContentStore.setMainContent(res);
            storyblokContentStore.setDefaultSlug(`/${defaultSlug}`);
            storyblokContentStore.setExtraContent(res);
            storyblokContentStore.setStoryId(storyId);
            storyblokContentStore.setIsTextRtl(isTextRtl);
            storyblokContentStore.setFirstPublishedAt(firstPublishedAt);
            storyblokContentStore.setPageLanguages(pageLanguages || null);
            filterStore.resetData();

            return {
                translatedSlugs,
                storyId,
                layoutToApply,
                colorSchema,
                colorSchemaOverride: extraData.colorSchemaOverride,
                mainText,
                mainContent: getNestedData(
                    [
                        'data',
                        'story',
                        'content',
                        'main_content',
                    ],
                    res,
                ),
                isTextRtl,
                extraData,
                hasNoHero,
                firstPublishedAt,
                hasDisabledPadding: !!storyContent.hasDisabledPadding,
                pageLanguages,
                defaultSlug,
                seoData: mappedSEOData,
                popup: isArrayNotEmpty(storyContent.popup) ? storyContent.popup[0] : null,
            };
        },
    },
);

watch(() => error.value, (newValue) => {
    if (newValue) {
        useSentryError(newValue, {
            path,
            version,
            language,
            resolve_links: 'link',
            resolve_relations: relationsToResolve.join(','),
        });

        throw createError({
            statusCode: '404',
            statusMessage: 'Whoops! Failed to fetch data from storyblok',
            fatal: true,
        });
    }
}, { immediate: true });

/*
    SEO
*/
watch(() => data.value, (newValue) => {
    if (newValue?.seoData) {
        setSEOData(newValue.seoData);
    }
}, { immediate: true, deep: true });

/*
  Storyblok Bridge
*/
if (process.client) {
    watch(() => data, (newValue) => {
        if (newValue) {
            if (version === 'draft') {
                useStoryblokBridge(newValue.value?.storyId, (evStory) => {
                    data.value.mainContent = evStory.content.main_content;
                });
            }
        }
    }, {
        immediate: true,
        deep: true,
    });
}
</script>

<style lang="scss">
.isRtl {
    direction: rtl;
}

._nuxt-page {
    // Force min-height to prevent layout shifts in header
    // When navigating between pages
    min-height: calc(100vh + 1px);

    .layout-news & {
        min-height: unset;
    }
}

.page-main-content {
    &.has-no-hero {
        @include fluid('padding-top', 20px, 58px);
    }
}
</style>
