export default () => {
    const createBlob = async (filename) => {
        const fetchedAsset = await $fetch('/api/fetch-asset', {
            method: 'POST',
            body: {
                filename,
            },
        });

        const blob = new Blob([fetchedAsset]);
        return blob;
    };

    const downloadImages = async (blob, index, name, isIterableName) => {
        if (!blob) {
            console.error('No blob data received');
            return;
        }
        const a = document.createElement('a');
        const imageName = isIterableName ? `${name}${index + 1}` : name;
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', imageName);
        a.click();
        URL.revokeObjectURL(a.href);
    };

    const getFileFormat = (url) => url.split('.').pop();

    const getImageName = (url) => url.split('/').pop();

    return {
        downloadImages, createBlob, getFileFormat, getImageName,
    };
};
