<template>
  <div
    ref="counterRef"
    class="block-counter"
  >
    <div class="block-counter__number">
      <span>{{ currentNumber }}{{ data.unit }}</span>
    </div>
    <div v-if="data.text" class="block-counter__text">
      <AtomRichtext
        :html="data.text"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const currentNumber = ref(0);
const target = ref(parseFloat(props.data.target));
const opacity = ref(0);
const calculateNumber = (progress) => {
    const calculatedNumber = progress * target.value;
    currentNumber.value = calculatedNumber.toFixed(
        props.data.decimals || 0,
    );
    opacity.value = progress;
};

const counterRef = ref(null);
onMounted(() => {
    const { createScrollTrigger } = useScrollTrigger();

    createScrollTrigger({
        trigger: counterRef.value,
        start: 'top bottom',
        end: 'top center',

        onUpdate: (_self) => {
            calculateNumber(_self.progress);
        },
    });
});
</script>

<style lang="scss" scoped>
.block-counter {
    @include fluid('padding-right', 0px, 60px);
    @include fluid('padding-left', 0px, 60px);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--parent-text-color, var(--c-white));

    .is-two-column & {
        justify-content: center;
    }
}

.block-counter__number {
    @include fluid('font-size', 96px, 206px);
    @include fluid('line-height', 105px, 210px);
    @include fluid('margin-bottom', 36px, 120px);
    @include fluid('margin-top', 36px, 120px);

    display: flex;
    flex-basis: 30%;
    align-items: center;
    align-self: center;
    justify-content: center;
    font-family: var(--f-family--black);
    opacity: v-bind(opacity);

    .is-two-column & {
        flex-basis: 100%;
    }

    @include mobile {
        flex-basis: 100%;
    }

    span {
        display: inline-block;
        width: 100%;
        text-align: center;
    }
}

.block-counter__text {
    flex-basis: 70%;

    .is-two-column & {
        flex-basis: 100%;
        align-self: center;
    }

    @include mobile {
        flex-basis: 100%;
    }
}
</style>
