<template>
  <div
    ref="spinnerRef"
    class="block-spinner"
  >
    <div class="block-spinner__icon">
      <IonIcon
        v-if="locale === 'de'"
        class="block-spinner__icon-outer"
        :icon-name="`coin-outer-default`"
      />

      <IonIcon
        v-if="locale === 'en'"
        class="block-spinner__icon-outer"
        :icon-name="`coin-outer-en`"
      />

      <IonIcon
        v-if="locale === 'it'"
        class="block-spinner__icon-outer"
        :icon-name="`coin-outer-it`"
      />

      <IonIcon
        v-if="locale === 'fr'"
        class="block-spinner__icon-outer"
        :icon-name="`coin-outer-fr`"
      />

      <div class="block-spinner__icon-inner">
        <div class="block-spinner__number">
          {{ data.number }}
        </div>
      </div>
    </div>
    <div v-if="data?.text" class="block-spinner__text">
      <AtomRichtext
        v-if="data?.text"
        :html="data.text"
      />
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const { locale } = useI18n();

const spinnerRef = ref(null);
const rotation = ref(0);
const opacity = ref(0);
const scale = ref(0);

const calculateValues = (progress) => {
    rotation.value = `${progress * 720}deg`;
    opacity.value = progress;
    scale.value = progress;
};

onMounted(() => {
    const { createScrollTrigger } = useScrollTrigger();

    createScrollTrigger({
        trigger: spinnerRef.value,
        start: 'top bottom',
        end: 'top center',

        onUpdate: (_self) => {
            calculateValues(_self.progress);
        },
    });
});
</script>

<style lang="scss" scoped>
.block-spinner__number {
    color: var(--parent-background-color);
    font-family: var(--f-family--black);

    @include fluid('font-size', 96px, 106px);
    @include fluid('line-height', 105px, 110px);
}

.block-spinner {
    @include fluid('padding-right', 0px, 60px);
    @include fluid('padding-left', 0px, 60px);

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: var(--parent-text-color, var(--c-white));

    .is-two-column & {
        justify-content: center;
    }

}

.block-spinner__icon {
    @include fluid('margin-bottom', 36px, 120px);
    @include fluid('margin-top', 36px, 120px);

    position: relative;
    display: flex;
    min-width: 206px;
    min-height: 206px;
    flex-basis: 30%;
    align-items: center;
    align-self: center;
    justify-content: center;
    opacity: v-bind(opacity);
    transform: scale(v-bind(scale));

    .is-two-column & {
        min-width: 100%;
        flex-basis: 100%;
    }

    span {
        display: inline-block;
        width: 100%;
        text-align: center;
        transform: translateY(-10%);
    }

    @include mobile {
        flex-basis: 100%;
    }

}

.block-spinner__icon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.block-spinner__icon-outer {
    width: 204px;
    height: 204px;
    transform: rotate(v-bind(rotation));

    @include mobile {
        position: absolute;
        left: 50%;
        transform: rotate(v-bind(rotation)) translate(-50%);
        transform-origin: 0 50%;
    }
}

.block-spinner__text {
    flex-basis: 70%;

    @include mobile {
        flex-basis: 100%;
    }

    .is-two-column & {
        flex-basis: 100%;
        align-self: center;
        text-align: center;
    }
}
</style>
