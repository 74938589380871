<template>
  <div class="block-article-teaser-wrapper">
    <UtilWrapperLayout class="block-article-teaser-wrapper">
      <UtilGrid>
        <UtilRouteLink
          class="block-article-teaser is-medium-wide"
          :class="{ 'is-reversed': direction === 'reverse' }"
          :link="link"
        >
          <div
            ref="leftColumnRef"
            class="block-article-teaser__column"
          >
            <AtomHeading
              font-size="h2"
              html-tag="h2"
              :text="title"
              class="block-article-teaser__title"
            />

            <AtomRichtext
              :html="text"
              class="block-article-teaser__title"
            />

            <ClientOnly>
              <AtomLink
                :link="link"
                :text="linkLabel"
                class="block-article-teaser__link"
                icon="chevron-right"
              />
            </ClientOnly>
          </div>

          <div
            ref="rightColumnRef"
            class="block-article-teaser__column"
          >
            <AtomImage
              v-if="image?.filename"
              :image="image"
              behaviour="cover"
              provider="storyblok"
              class="block-article-teaser__image"
            />
          </div>
        </UtilRouteLink>
      </UtilGrid>
    </UtilWrapperLayout>
  </div>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true,
    },

    text: {
        type: String,
        required: true,
    },

    image: {
        type: Object,
        default: () => ({}),
    },

    direction: {
        type: String,
        default: 'normal',
    },

    link: {
        type: [String, Object],
        default: '',
    },

    linkLabel: {
        type: String,
        default: 'Mehr erfahren',
    },
});

const distance = 100;
const leftColumnRef = ref(null);
const rightColumnRef = ref(null);

onMounted(() => {
    const { addTrigger: addLeftTrigger } = useTransformScroll({
        el: leftColumnRef.value,
        varBase: 'column',
        ease: 'easeOutSine',
        negate: props.direction !== 'reverse',
        triggerStart: 'top bottom',
        triggerEnd: 'center center',
        properties: {
            x: {
                distance: distance / 2,
                unit: 'px',
            },
        },
    });

    const { addTrigger: addRightTrigger } = useTransformScroll({
        el: rightColumnRef.value,
        varBase: 'column',
        negate: props.direction === 'reverse',
        ease: 'easeOutSine',
        triggerStart: 'top bottom',
        triggerEnd: 'center center',
        properties: {
            x: {
                distance: distance / 2,
                unit: 'px',
            },
        },
    });

    addLeftTrigger();
    addRightTrigger();
});

const colorSchema = inject('colorSchema');
const color7 = computed(() => `var(--c-${colorSchema.value}-7)`);
</script>

<style lang="scss" scoped>
.block-article-teaser-wrapper {
    @include fluid('margin-bottom', 60px, 120px);

    overflow: hidden;

    @include mobile {
        width: 100%;
        max-width: 100vw;
    }
}

.block-article-teaser__icon {
    display: block;
    margin: 0 auto;
    transition: transform 0.6s $EASE_IN_OUT--BACK;
}

.block-article-teaser {
    @include default-content-columns;
    @include grid-layout(2, 0px);

    ::v-deep(.atom-heading) {
        color: var(--c-white);
    }

    ::v-deep(.atom-richtext) {
        color: var(--c-white);
    }

    ::v-deep(.atom-link__text) {
        color: var(--c-white);
    }

    ::v-deep(.atom-link__icon) {
        --color--4: var(--c-white) !important;
    }

    @include mobile {
        @include grid-layout(1, 0px);
    }

    &:hover {
        .block-article-teaser__icon {
            transform: translateX(35px);
        }
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.block-article-teaser__column {
    order: 1;
    margin: var(--column-margin) 0;
    background: v-bind(color7);
    transform: var(--column-transform);

    &:first-child {
        @include fluid('padding', 20px, 80px);

        @include mobile {
            order: 3;
        }
    }

    .is-reversed & {
        &:last-child {
            order: -1;

        }
    }
}

.block-article-teaser__title,
.block-article-teaser__text {
    margin-bottom: 20px;
}

.block-article-teaser__image {
    @include mobile {
        aspect-ratio: 1 / 1;
    }
}
</style>
